<!-- <template>
    <div id="app">
        itMemberPage.vue
        <!-- <div id="waline"></div> -->
    </div>
</template>
<script>
// import { init } from '@waline/client';
// import { init } from '@waline/client/full';
// import '@waline/client/dist/waline.css';
export default {
    mounted() {
        // init({
        //     el: '#waline',
        //     serverURL:"https://at-website-waline.vercel.app/",
        //     emoji:["//unpkg.com/@waline/emojis@1.1.0/bilibili"],
        //     search:false,
        //     avatar:"hide",

            
        // });
    },
    data() {
        return {

        }
    }
}
</script>

<style>
.wl-actions{
    display: none;
}
/* .wl-user{
    display: none;
} */
.wl-power{
    display: none;
}
</style> -->
