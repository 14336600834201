<template>
    <div id="app">
        404 NotFound
    </div >
</template>
<script>
export default {

    data() {
        return {

        }
    }
}
</script>

<style>

</style>
